import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';

import HomePage from './components/HomePage';
import TradingEducationChatbot from './components/TradingEducationChatbot';

function App() {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setUser(tokenResponse);
    },
    onError: (error) => {
      console.log('Login Failed:', error);
    },
  });

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const logOut = () => {
    googleLogout();
    setUser(null);
    setProfile(null);
  };

  return (
    <Router>
      <div className="App min-h-screen bg-background text-foreground">
        <header className="p-4 flex justify-between items-center bg-background">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-gray-300">
              TradingTutor GPT
            </Link>
          </div>
          <div>
            {profile ? (
              <div className="flex items-center space-x-4">
                <img src={profile.picture} alt="User" className="w-8 h-8 rounded-full" />
                <span className="text-gray-300">{profile.given_name}</span>
                <button
                  onClick={logOut}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  Logout
                </button>
              </div>
            ) : (
              <button
                onClick={() => login()}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Login with Google
              </button>
            )}
          </div>
        </header>
        <main className="container mx-auto p-4">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/chatbot" element={<TradingEducationChatbot />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
