import React from 'react';
import { Link } from 'react-router-dom';
import qrCodeImage from '../data/bmc_qr.png'; // Adjust the path as necessary
import xIcon from '../data/icons8-done-arcade-96.png'; // Add this import

const HomePage = () => {
  return (
    <div className="bg-background min-h-screen text-gray-100">
      {/* Hero Section */}
      <div className="bg-background py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-100 sm:text-4xl md:text-5xl">
              <span className="block mb-2">An AI-augmented learning experience</span>
              <span className="block text-blue-300">TradingTutor GPT</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-300 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Master trading and finance knowledge through interactive Q&A
            </p>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-300 sm:text-lg md:mt-1 md:text-xl md:max-w-3xl">
              With our AI
            </p>

            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link
                  to="/chatbot"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-blue-400 hover:bg-blue-800 md:py-4 md:text-lg md:px-10 transition duration-300"
                >
                  Start today's study session
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-xl text-blue-400 font-semibold tracking-wide uppercase">
              Give it a try!
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              Because we are tired of textbooks and hours of lectures too!
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-300 mx-auto">
              Simply and quickly learn the concepts you need to know
            </p>
          </div>

          <div className="mt-10">
            {/* Features Grid */}
            <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {/* Feature 1 */}
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                    <img src={xIcon} alt="Icon" className="h-8 w-8" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-100">AI Tutor</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-300">
                  Learn from an AI expert in trading and finance, always ready to share its vast
                  knowledge.
                </dd>
              </div>

              {/* Feature 2 */}
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                    <img src={xIcon} alt="Icon" className="h-8 w-8" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-100">
                    Interactive Lessons
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-300">
                  Engage with interactive Q&A that make learning trading concepts fun and easy.
                  Learn at your own pace with instant feedback and explanations.
                </dd>
              </div>

              {/* Feature 3 */}
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                    <img src={xIcon} alt="Icon" className="h-8 w-8" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-100">
                    Adaptive Teaching
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-300">
                  Every question adapts to your knowledge level, ensuring a personalized learning
                  journey.
                </dd>
              </div>

              {/* Feature 4 */}
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                    <img src={xIcon} alt="Icon" className="h-8 w-8" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-100">
                    Comprehensive Topics
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-300">
                  Cover a wide range of topics from basic concepts to advanced trading strategies.
                </dd>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Donation Section */}
      <div className="py-12 bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-2xl text-yellow-400 font-semibold tracking-wide uppercase">
              Support Us!
            </h2>
            <p className="mt-4 max-w-6xl text-xl text-gray-200 mx-auto">
              TradingTutor GPT is a free app dedicated to helping you master trading and finance through interactive learning.</p>
            <p className="mt-4 max-w-6xl text-xl text-gray-200 mx-auto">
              Our app could go offline if it becomes unaffordable for us to maintain (paying for server rental and API key costs).</p>
            <p className="mt-4 max-w-6xl text-xl text-gray-200 mx-auto">
              We rely on the generosity of users like you! </p>
            <p className="mt-4 max-w-6xl text-xl text-gray-200 mx-auto">
              So if you find our app helpful, please consider supporting us.</p>
            <p className="mt-4 max-w-6xl text-3xl text-yellow-400 mx-auto">
                Your contribution makes a difference!</p>
          </div>
          <div className="mt-10 flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-12">
            {/* Buy Me a Coffee Button */}
            <a
              href="https://www.buymeacoffee.com/zengp"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-yellow-400 hover:bg-yellow-500 transition duration-300"
            >
              <svg
                className="w-6 h-6 mr-2"
                fill="currentColor"
                viewBox="0 0 512 512"
                aria-hidden="true"
              >
                {/* Coffee Cup Icon */}
                <path d="M432 64H80c-17.67 0-32 14.33-32 32v64c0 8.84 7.16 16 16 16h16v192c0 53.02 42.98 96 96 96h144c53.02 0 96-42.98 96-96V176h16c44.11 0 80-35.89 80-80V96c0-17.67-14.33-32-32-32zM368 368c0 35.29-28.71 64-64 64H160c-35.29 0-64-28.71-64-64V176h272v192zm96-272c0 26.47-21.53 48-48 48h-16V112h64v-16z" />
              </svg>
              Buy Me a Coffee
            </a>

            {/* QR Code Image */}
            <div className="flex flex-col items-center">
              <img
                src={qrCodeImage}
                alt="Donate via QR Code"
                className="w-48 h-48 object-contain"
              />
              <p className="mt-2 text-gray-400">Scan to donate</p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer or any other sections */}
    </div>
  );
};

export default HomePage;
