import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button } from './ui/button';
import { Card, CardContent } from './ui/card';
import { Loader } from 'lucide-react';
import { generateQuestion, generateResponse, generateStudyNote } from '../services/openai';
import { TRADING_EDUCATION_PROMPT } from '../config/prompts';
import ReactMarkdown from 'react-markdown';
import lecturesData from '../data/lectures.json';
import { motion } from 'framer-motion'; // Import Framer Motion for animations

const TradingEducationChatbot = () => {
  const [messages, setMessages] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [studyNote, setStudyNote] = useState('');
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isTopicSelection, setIsTopicSelection] = useState(true);
  const [isStudyEnded, setIsStudyEnded] = useState(false); // New state variable

  const systemPrompt = useMemo(() => ({
    role: "system",
    content: TRADING_EDUCATION_PROMPT
  }), []);

  const generateNewQuestion = useCallback(async () => {
    if (!selectedTopic) return;

    setIsLoading(true);
    try {
      const topicPrompt = {
        role: "system",
        content: `
You are an experienced and patient AI tutor specialized in teaching "${selectedTopic.topic}".

**Objective:**
Help the student understand and master the topic through interactive multiple-choice questions and detailed explanations.

**Instructions:**

- **Question Generation:**
  - Create a multiple-choice question related to "${selectedTopic.description}".
  - The question should focus on key concepts and encourage critical thinking.
  - Provide **four** answer options:
    - One correct answer.
    - Three plausible but incorrect options (distractors).
  - Ensure the distractors are reasonable and challenging.

- **Explanation:**
  - After the student selects an answer, provide a detailed explanation.
  - If the answer is correct:
    - Acknowledge the correct choice with positive reinforcement.
    - Expand on the concept to deepen understanding.
  - If the answer is incorrect:
    - Gently correct the student without negativity.
    - Explain why the chosen option is incorrect.
    - Provide the correct answer with an explanation.

- **Content Coverage:**
  - Focus on the following key points, but feel free to expand on related concepts:
${selectedTopic.points.map((point, index) => `    ${index + 1}. ${point}`).join("\n")}

- **Tone and Language:**
  - Use clear, concise, and beginner-friendly language.
  - Maintain a positive, encouraging, and supportive tone.
  - Avoid jargon unless it's explained.

- **Progression:**
  - After explaining, proceed to generate the next question, building on previous concepts.

**Remember:**
Your goal is to enhance the student's understanding and keep them engaged. Be adaptive and responsive to the student's inputs.
`
      };

      const questionData = await generateQuestion([topicPrompt, ...messages]);
      setCurrentQuestion(questionData.question);
      setOptions(questionData.options);
    } catch (error) {
      console.error('Error generating question:', error);
      setCurrentQuestion('Failed to generate question. Please try again.');
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  }, [messages, selectedTopic]);

  useEffect(() => {
    if (isTopicSelection) {
      setCurrentQuestion("What topic would you like to learn about today?");
      setOptions(lecturesData.lectures.map(lecture => lecture.topic));
    } else if (selectedTopic && !isStudyEnded) { // Add condition to prevent generating new question after study ends
      generateNewQuestion();
    }
  }, [isTopicSelection, selectedTopic, generateNewQuestion, isStudyEnded]);

  const handleAnswer = async (selectedOption) => {
    if (isStudyEnded) return; // Prevent handling answers after study has ended

    setIsLoading(true);
    try {
      if (isTopicSelection) {
        const selected = lecturesData.lectures.find(lecture => lecture.topic === selectedOption);
        setSelectedTopic(selected);
        setIsTopicSelection(false);
        setMessages([
          ...messages,
          { role: "assistant", content: currentQuestion },
          { role: "user", content: selectedOption },
          { role: "assistant", content: `Great choice! Let's start learning about ${selectedOption}. Please answer the questions below.` }
        ]);
      } else {
        const newMessages = [
          ...messages,
          { role: "assistant", content: currentQuestion },
          { role: "user", content: selectedOption }
        ];

        const aiResponse = await generateResponse([systemPrompt, ...newMessages]);

        const updatedMessages = [
          ...newMessages,
          { role: "assistant", content: aiResponse }
        ];

        setMessages(updatedMessages);
        await generateNewQuestion();
      }
    } catch (error) {
      console.error('Error in handleAnswer:', error);
      setCurrentQuestion('Failed to process answer. Please try again.');
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEndStudy = async () => {
    setIsLoading(true);
    try {
      const note = await generateStudyNote([systemPrompt, ...messages]);
      setStudyNote(note);
      setIsStudyEnded(true); // Set study ended to true
    } catch (error) {
      console.error('Error generating study note:', error);
      setStudyNote('Failed to generate study note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestartStudy = () => {
    setMessages([]);
    setCurrentQuestion(null);
    setOptions([]);
    setStudyNote('');
    setSelectedTopic(null);
    setIsTopicSelection(true);
    setIsStudyEnded(false);
  };

  return (
    <div className="flex flex-col h-screen max-w-2xl mx-auto font-sans p-4 bg-gray-900 text-white">
      {/* Header */}
      <header className="text-center mb-6">
        <h1 className="text-3xl font-bold text-white">TradingTutor GPT</h1>
        <p className="text-white">Enhance your finance knowledge interactively</p>
      </header>

      {/* Chat Messages */}
      <div className="flex-grow overflow-auto mb-4 space-y-4">
        {messages.map((msg, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Card
              className={`${
                msg.role === 'user'
                  ? 'ml-auto bg-gray-700 text-white'
                  : 'mr-auto bg-gray-800 text-white'
              } max-w-[80%] shadow-lg rounded-lg`}
            >
              <CardContent className="p-4">
                <ReactMarkdown className="prose max-w-none prose-invert text-l text-white">
                  {msg.content}
                </ReactMarkdown>
              </CardContent>
            </Card>
          </motion.div>
        ))}

        {/* Loading Indicator */}
        {isLoading && (
          <div className="flex items-center justify-center">
            <Loader className="animate-spin mr-2 text-white" />
            <span className="text-white">Our tutor is preparing...</span>
          </div>
        )}
      </div>

      {/* Current Question and Options */}
      {currentQuestion && !isLoading && !isStudyEnded && (
        <motion.div
          className="mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <Card className="w-full bg-gray-800 text-white shadow-md rounded-lg">
            <CardContent className="p-4">
              <h2 className="text-xl font-semibold mb-2 text-white">Question:</h2>
              <ReactMarkdown className="prose max-w-none prose-invert text-l text-white">
                {currentQuestion}
              </ReactMarkdown>
            </CardContent>
          </Card>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
            {Array.isArray(options) &&
              options.map((option, index) => (
                <motion.button
                  key={index}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleAnswer(option)}
                  className="w-full bg-gray-700 text-left p-4 rounded-lg shadow hover:bg-gray-600 transition-colors duration-200"
                  title={
                    isTopicSelection
                      ? lecturesData.lectures.find((lecture) => lecture.topic === option)?.description
                      : 'Select this option'
                  }
                >
                  <span className="text-white">{option}</span>
                </motion.button>
              ))}
          </div>
        </motion.div>
      )}

      {/* End Study Button */}
      {!isStudyEnded && (
        <div className="flex justify-center mt-6">
          <Button
            onClick={handleEndStudy}
            disabled={isLoading || isTopicSelection}
            className="bg-green-600 hover:bg-green-500 text-white font-semibold py-3 px-6 rounded-full shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50"
          >
            End Study & Get Summary
          </Button>
        </div>
      )}

      {/* Study Note */}
      {studyNote && (
        <motion.div
          className="mt-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <Card className="w-full bg-gray-800 text-white shadow-lg rounded-lg">
            <CardContent className="p-4">
              <ReactMarkdown
                className="prose prose-sm max-w-none prose-invert"
                components={{
                  h1: ({ node, ...props }) => (
                    <h1 className="text-2xl font-bold mb-2 text-white" {...props}>
                      {props.children}
                    </h1>
                  ),
                  h2: ({ node, ...props }) => (
                    <h2 className="text-xl font-semibold mb-2 text-white" {...props}>
                      {props.children}
                    </h2>
                  ),
                  ul: ({ node, ...props }) => (
                    <ul className="list-disc pl-5 mb-2" {...props} />
                  ),
                  li: ({ node, ...props }) => <li className="mb-1" {...props} />,
                }}
              >
                {studyNote}
              </ReactMarkdown>
            </CardContent>
          </Card>

          {/* Restart Study Session Button */}
          <div className="flex justify-center mt-6">
            <Button
              onClick={handleRestartStudy}
              className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-3 px-6 rounded-full shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Start a New Study Session
            </Button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default TradingEducationChatbot;
