import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true // Note: This is not recommended for production
});

export const generateQuestion = async (messages) => {
  try {     
    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        ...messages,
        { 
          role: "user", 
          content: `
            You are an experienced and patient AI tutor specialized in finance and trading education.

            **Objective:**
            Help the student understand and master the topic through interactive multiple-choice questions.

            **Instructions:**

            - **Question Generation:**
              - Create a multiple-choice question related to the topics discussed so far.
              - The question should focus on key concepts, so after the student chose the topic, there are points listed, based on those points and expand those points, the goal is to help the student learn.
              - encourage critical thinking.
              - Provide **four** answer options:
                - One correct answer.
                - Three plausible but incorrect options (distractors).
              - Ensure the distractors are reasonable and challenging.

            - **Format:**
              - Present the question and options in a JSON object with the following structure:
                \`\`\`json
                {
                  "question": "Your generated question here",
                  "options": ["Option 1", "Option 2", "Option 3", "Option 4"]
                }
                \`\`\`
              - Ensure the response starts with \`{\` and ends with \`}\`, strictly in JSON format, with no additional text or characters.

            - **Content Coverage:**
              - Focus on the key points provided in previous messages, but feel free to expand on related concepts.

            **Note:**
            Your response should only contain the JSON object. Do not include any explanations or additional text.
            `
            }
          ],
          temperature: 0.7,
          max_tokens: 500,
        });
        
        // Remove code block formatting if present
        const cleanedContent = response.choices[0].message.content.replace(/```json\n|\n```/g, '').trim();
        
        console.log(cleanedContent);
        return JSON.parse(cleanedContent);
      } catch (error) {
        console.error('Error generating question:', error);
        throw error;
      }
    };

    export const generateResponse = async (messages) => {
      try {
        const response = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [
            ...messages,
            { 
              role: "user", 
              content: `
                You are an experienced and patient AI tutor specialized in finance and trading education.
                
                **Objective:**
                Evaluate the student's answer to the previous question, provide feedback, and offer a detailed explanation to enhance understanding.
                
                **Instructions:**
                
                - **Evaluation:**
                  - Determine if the student's selected option is correct based on the previous question and answer options.
                  - If the answer is correct:
                    - Congratulate the student with positive reinforcement.
                    - Provide a detailed explanation expanding on the concept.
                  - If the answer is incorrect:
                    - Gently inform the student that the answer is not correct without negativity.
                    - Explain why the chosen option is incorrect.
                    - Provide the correct answer with a detailed explanation.
                
                - **Tone and Language:**
                  - Use clear, concise, and beginner-friendly language.
                  - Maintain a positive, encouraging, and supportive tone.
                  - Avoid jargon unless it's explained.
                
                - **Continuation:**
                  - Encourage the student to continue to the next question.
                
                **Note:**
                Your response should be concise yet informative, focusing on enhancing the student's understanding.
                At end of the conversation, do not ask the user to ask further questions, just say someting like "let's move on to the next question" etc.
                `
            }
          ],
          temperature: 0.7,
          max_tokens: 500,
        });
        return response.choices[0].message.content;
      } catch (error) {
        console.error('Error generating response:', error);
        throw error;
      }
    };
    
    export const generateStudyNote = async (messages) => {
      try {
        const response = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [
            ...messages,
            { 
              role: "user", 
              content: `
                You are an experienced and supportive AI tutor specializing in finance and trading education.
                
                **Objective:**
                Generate a comprehensive study note summarizing the student's learning session based on the conversation history.
                
                **Instructions:**
                
                - **Summary:**
                  - Provide a detailed summary of the key concepts and topics covered during the session.
                  - Include main concepts, definitions, and any important tips or strategies discussed.
                  - Reference specific questions and answers from the session, highlighting both correct and incorrect responses.
                  - For any questions the student answered incorrectly, gently explain the correct concepts and suggest areas for improvement.
                
                - **Encouragement:**
                  - Offer positive reinforcement to acknowledge the student's efforts.
                  - Encourage the student to continue their learning journey and apply the concepts learned.
                
                - **Format:**
                  - Organize the study note in a clear and structured manner, using headings and bullet points where appropriate.
                  - Format the response in Markdown.
                
                **Tone and Language:**
                - Use clear, concise, and beginner-friendly language.
                - Maintain a positive, encouraging, and supportive tone.
                - Avoid jargon unless it's explained.
                
                **Note:**
                Your response should be based solely on the conversation history provided, ensuring accuracy and relevance to the student's learning experience.
                `
            }
          ],
          temperature: 0.7,
          max_tokens: 1000,
        });
        return response.choices[0].message.content;
      } catch (error) {
        console.error('Error generating study note:', error);
        throw error;
      }
    };
    